import { getPendingStatus, postTransactionAction } from "api";
import axios from "axios";
import { BOG_URL } from "config";
import { useQueryString } from "hooks";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { decrypt, encrypt } from "utils/encryption";
import apple from "assets/images/icon/appstoreupdated.svg";
import googleIcon from "assets/images/icon/googleplayupdated.svg";
import { useTranslation } from "react-i18next";
import Lottie from "lottie-react";
import TransactionCompleteAnim from "../../utils/pendincAnimation.json";

const StandardPending = () => {
  const { parseQuery: parsedQuery } = useQueryString();
  const { t } = useTranslation();

  const {
    acquiringTransactionId,
    ratingEnabled = "false",
    amount = "",
    receiverName = "",
    receiverId = "",
    receiverType = "",
    tipsEnabled = "false",
    token = "",
    currency = "",
    code = "",
    paymentProviderEnum = "",
    state = "",
    error = false,
  } = parsedQuery;
  const navigate = useNavigate();
  const formRef = useRef(null);
  const inputRef = useRef(null);

  useEffect(() => {
    if (paymentProviderEnum == "TBC") {
      const encryptedData = encrypt({
        id: acquiringTransactionId,
      });
      axios
        .post(
          `${process.env.REACT_APP_API_URL}/payment-service/api/external/tbc/transaction/info`,
          {
            identifier: process.env.REACT_APP_INTEGRATOR_ID,
            encryptedData: encryptedData,
          }
        )
        .then((data) => {
          const {
            data: { encryptedData },
          } = data;
          const decryptedData = decrypt(encryptedData);

          if (decryptedData?.tbcTransactionStatus == "Succeeded") {
            const successUrl = `/success?acquiringTransactionId=${acquiringTransactionId}&amount=${amount}&receiverName=${receiverName}&ratingEnabled=${ratingEnabled}&tipsEnabled=${tipsEnabled}&receiverType=${receiverType}&currency=${currency}&receiverId=${receiverId}&paymentProviderEnum=TBC&paymentMethod=STANDARD`;
            navigate(successUrl);
          } else {
            navigate(
              `/payment-failed?acquiringTransactionId=${acquiringTransactionId}&receiverId=${receiverId}&receiverType=${receiverType}`
            );
          }
        });
      return;
    }
    if (paymentProviderEnum == "CREDO") {
      const encryptedData = encrypt(
        { id: acquiringTransactionId },
        { shouldEncode: true }
      );

      axios
        .get(
          `${
            process.env.REACT_APP_API_URL
          }/payment-service/api/external/credo/transaction/info?identifier=${encodeURI(
            process.env.REACT_APP_INTEGRATOR_ID
          )}&encryptedData=${encryptedData}`
        )
        .then((data) => {
          const {
            data: { encryptedData },
          } = data;
          const decryptedData = decrypt(encryptedData);

          if (decryptedData?.credoTransactionStatus == "FINISHED") {
            const successUrl = `/success?acquiringTransactionId=${acquiringTransactionId}&amount=${amount}&receiverName=${receiverName}&ratingEnabled=${ratingEnabled}&tipsEnabled=${tipsEnabled}&receiverType=${receiverType}&currency=${currency}&receiverId=${receiverId}&paymentProviderEnum=CREDO&paymentMethod=STANDARD`;
            navigate(successUrl);
          } else {
            navigate(
              `/payment-failed?acquiringTransactionId=${acquiringTransactionId}&receiverId=${receiverId}&receiverType=${receiverType}`
            );
          }
        });
      return;
    }

    if (token) {
      axios
        .get(
          `${process.env.REACT_APP_API_URL}/api/gc/transaction/info?providerId=${token}&fromMobile=false`
        )
        .then((data) => {
          if (data?.result?.status == "FAILED") {
            navigate(
              `/payment-failed?acquiringTransactionId=${acquiringTransactionId}&receiverId=${receiverId}&receiverType=${receiverType}`
            );
          } else if (data?.result?.status == "SUCCESS") {
            const successUrl = `/success?acquiringTransactionId=${acquiringTransactionId}&amount=${amount}&receiverName=${receiverName}&ratingEnabled=${ratingEnabled}`;
            navigate(successUrl);
          }
        });

      return;
    }
    const sessionId = localStorage.getItem("sessionId");
    if (sessionId) {
      async function handleStatusRequest() {
        const options = {
          method: "POST",
          url: `${BOG_URL}/payment/${sessionId}`,
        };

        const {
          data: { url, post = {} },
        } = await axios.request(options);
        console.log(post, "სტატუსის დატა");

        if (!url) {
          throw new Error("Something went wrong");
        }

        formRef.current.action = url;
        if ("threeDSMethodData" in post) {
          inputRef.current.name = "threeDSMethodData";
          inputRef.current.value = post.threeDSMethodData;
        } else {
          inputRef.current.name = "creq";
          inputRef.current.value = post.creq;
        }

        localStorage.removeItem("sessionId");
        formRef.current.submit();
        return;
      }

      handleStatusRequest();
    }
    (async () => {
      const data = await getPendingStatus(acquiringTransactionId);
      const decryptedData = decrypt(data.data.encryptedData);
      const integratorOrderId = localStorage.getItem("integratorOrderId");
      const { redirectRequired, successRedirectUri, failRedirectUri } =
        await postTransactionAction(integratorOrderId);
      if (decryptedData?.status === "SUCCESS" && tipsEnabled) {
        const successUrl = `/success?acquiringTransactionId=${acquiringTransactionId}&amount=${amount}&receiverName=${receiverName}&ratingEnabled=${ratingEnabled}&tipsEnabled=${tipsEnabled}&paymentMethod=google&paymentProviderEnum=BOG&receiverId=${receiverId}&receiverType=${receiverType}`;
        navigate(successUrl);
        return;
      }
      if (redirectRequired) {
        if (decryptedData?.status === "SUCCESS") {
          window.location.href = successRedirectUri;
        } else {
          window.location.href = failRedirectUri;
        }
      } else {
        if (decryptedData?.status === "SUCCESS") {
          const successUrl = `/success?acquiringTransactionId=${acquiringTransactionId}&amount=${amount}&receiverName=${receiverName}&ratingEnabled=${ratingEnabled}&paymentMethod=google&paymentProviderEnum=BOG&receiverId=${receiverId}`;

          navigate(successUrl);
        } else {
          navigate(
            `/payment-failed?acquiringTransactionId=${acquiringTransactionId}&receiverId=${receiverId}&receiverType=${receiverType}`
          );
        }
      }
    })();
  }, []);

  return (
    <div className="w-[100%] h-[100%] flex justify-around items-center flex-col relative">
      <div className="flex justify-center items-center flex-col gap-1  ">
        <div className="h-[100px] w-[100px] bg-[#FFA500] rounded-[50%] flex justify-center items-center">
          <Lottie
            animationData={TransactionCompleteAnim}
            style={{ width: "55px", height: "55px" }}
          />
        </div>
        <p className="text-[20px] text-[#170738] mt-[20px]">
          Payment is processing
        </p>
        <div style={{ transform: "translateY(-30px)", marginTop: "20px" }}>
          <p className="opacity-50 text-center whitespace-pre-line">
            It may take a few seconds
          </p>
        </div>
      </div>
      <div className="flex flex-col justify-center items-center  ">
        <div className="flex items-center justify-center gap-[10px]">
          <a
            href="https://apps.apple.com/us/app/tip-app-mobile/id1579469134"
            target="_blank"
            rel="noreferrer"
          >
            <img src={apple} alt="#" width="158px" height="52px" />
          </a>
          <a
            href="https://play.google.com/store/apps/details?id=com.tipapp"
            target="_blank"
            rel="noreferrer"
          >
            <img src={googleIcon} alt="#" width="158px" height="52px" />
          </a>
        </div>
        <div className="flex items-center justify-center w-full pb-10 self-end p-4">
          <button
            className="max-w-2xl  w-full inline-flex justify-center gap-2 p-4 text-[20px] text-[#6C63FF] font-[600] mt-4 rounded-2xl disabled:bg-gray-400 text-center items-center"
            style={{ height: "50px", borderRadius: "8px" }}
            onClick={() => {
              const currentURL = window.location.href;
              const url = new URL(currentURL);
              const newURL = `${url.origin}`;
              window.location.href = newURL;
            }}
          >
            {t("description.done")}
          </button>
        </div>
      </div>
      <form ref={formRef} method="post" action="" className="absolute">
        <input type="hidden" name="" ref={inputRef} value="" />
        <noscript>
          <center>
            <br />
            Please click the submit button below.
            <br />
            <input type="submit" value="Submit" />
          </center>
        </noscript>
      </form>
    </div>
  );
};

export default StandardPending;
