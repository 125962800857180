import {
  acceptTbcGooglePayPayment,
  acceptUfcGooglePayPayment,
  tipOBRequest,
  tipWithApplePay,
  tipWithApplePayCredo,
  tipWithApplePayTbc,
  tipWithCard,
  tipWithCardCredo,
  tipWithCardTbc,
  tipWithGooglePay,
  tipWithGooglePayCredo,
  tipWithGooglePayTbc,
} from "api";
import apple from "assets/images/icon/appstoreupdated.svg";
import googleIcon from "assets/images/icon/googleplayupdated.svg";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import TipModal from "./TipModal";
// import CustomInputModal from "./CustomTipModal";
import {
  baseCardPaymentMethod,
  baseCardPaymentMethodTbc,
  baseRequest,
  cardPaymentMethod,
  cardPaymentMethodTbc,
  cardPaymentMethodUfc,
} from "../../bog/config";
import {
  MERCHANT_NAME,
  GPAY_MERCHANT_ID,
  BOG_URL,
} from "../../../../src/config";
import { acceptGooglePayPayment } from "../../bog/api";
import { useQueryString } from "hooks";
import axios from "axios";
import startApplePaySession from "features/apple/utils/startApplePaySession";
import startApplePaySessionTbc from "features/apple/utils/startApplePaySessionTbc";
import startApplePaySessionUfc from "features/apple/utils/startApplePaySessionUfc";

const MERCHANT_IDENTIFIER = "merchant.bog.keepz";
const MERCHANT_IDENTIFIER_TBC = "merchant.tbc.keepz";
const MERCHANT_IDENTIFIER_CREDO = "merchant.keepz.credo";

export default function DownloadApps({
  amount,
  transactionId,
  tipsEnabled,
  receiverType,
  commisionAmount,
  skip,
  receiverName,
  currency,
  currencyRate,
  paymentProviderEnum,
  paymentMethod,
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [tip, setTip] = useState();
  const [showModal, setShowModal] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  // const [inputModal, setInputModal] = useState(false);

  const { parseQuery: parsedQuery } = useQueryString();
  const {
    // paymentProviderEnum = "BOG",
    // receiverName = "",
    receiverId = "",
  } = parsedQuery;

  const gpayRef = useRef();
  const formRef = useRef(null);
  const inputRef = useRef(null);

  // const paymentMethod = localStorage.getItem("paymentMethod");
  const commisionPerncet = commisionAmount;

  function roundDecimalToNextHundredth(number) {
    // Multiply the number by 100, round it up to the nearest integer, then divide it by 100
    const errNum = (number * 100).toFixed(2);
    return Math.ceil(errNum) / 100;
  }

  const tipAmount = tip ? (amount * tip) / 100 : null;
  const roundedTip = tip ? roundDecimalToNextHundredth(tipAmount) : null;
  const commison =
    commisionPerncet && tip
      ? (roundedTip * Number(commisionPerncet)) / 100
      : null;
  const roundedCommision =
    commison && tip ? roundDecimalToNextHundredth(commison) : 0.0;

  const totalWithCommision =
    commisionPerncet && tip ? (roundedTip + roundedCommision).toFixed(2) : null;

  function convertToEur(amount) {
    return Math.ceil((Number(amount) * currencyRate * 100).toFixed(2)) / 100;
  }

  const convertedAmount = convertToEur(roundedTip);
  const acquiringType = localStorage.getItem("acquiring");
  const handlTips = async () => {
    try {
      if (paymentMethod == "STANDARD") {
        if (paymentProviderEnum == "TBC") {
          const { data } = await tipWithCardTbc(
            transactionId,
            roundedTip,
            tip,
            roundedCommision
          );

          const { links } = data;
          const [, secondElement] = links;
          const { uri } = secondElement;
          window.open(uri, "_self");
        } else if (paymentProviderEnum == "CREDO") {
          const { clientHandlerUrl } = await tipWithCardCredo(
            transactionId,
            roundedTip,
            tip,
            roundedCommision
          );

          window.open(clientHandlerUrl, "_self");
        } else {
          const { data } = await tipWithCard(
            transactionId,
            roundedTip,
            tip,
            roundedCommision
          );

          window.open(data._links.redirect.href, "_self");
        }
      } else if (paymentMethod == "google") {
        if (paymentProviderEnum == "BOG") {
          const paymentDataRequest = Object.assign({}, baseRequest);
          paymentDataRequest.allowedPaymentMethods = [cardPaymentMethod];
          paymentDataRequest.transactionInfo = {
            totalPriceStatus: "FINAL",
            totalPrice:
              currency == "EUR"
                ? convertedAmount.toString()
                : (((Math.ceil(amount * tip) / 100) * 100) / 100).toFixed(2),
            currencyCode: "GEL",
            countryCode: "GE",
          };
          paymentDataRequest.merchantInfo = {
            merchantName: MERCHANT_NAME,
            merchantId: GPAY_MERCHANT_ID,
          };
          let googlePayResponse;
          try {
            googlePayResponse = await tipWithGooglePay(
              transactionId,
              roundedTip,
              tip,
              roundedCommision,
              convertedAmount,
              currency
            );
          } catch (err) {
            console.log(err);
            return;
          }

          gpayRef.current
            .loadPaymentData(paymentDataRequest)
            .then(async (paymentData) => {
              try {
                const token =
                  paymentData.paymentMethodData.tokenizationData.token;
                const cardNetwork =
                  paymentData.paymentMethodData.info.cardNetwork;
                const {
                  sessionId,
                  params: { transaction_id: merchantTransactionId } = {},
                } = googlePayResponse;

                const {
                  state,
                  url = "",
                  result: { status = "" } = {},
                } = await acceptGooglePayPayment(token, {
                  sessionId,
                  merchantTransactionId,
                  cardBrand: cardNetwork,
                });
                if (status === "SUCCESS") {
                  const redirectURL = `https://app.keepz.me/success?acquiringTransactionId=${merchantTransactionId}&amount=${(
                    (amount * tip) /
                    100
                  ).toFixed(
                    2
                  )}&receiverName=${receiverName}&ratingEnabled=false&tipsEnabled=false&paymentProviderEnum=${paymentProviderEnum}&currency=${
                    currency == "EUR" ? "EUR" : "GEL"
                  }&currencyRate=${currencyRate}`;
                  window.open(redirectURL, "_self");
                  return;
                } else if (state === "3ds2_prepare") {
                  const version = localStorage.getItem("version");

                  // const iframeReturnUrl = `https://app.keepz.me/success?acquiringTransactionId=${merchantTransactionId}&amount=${fixedAmount}&receiverName=${encodeURIComponent((merchant || data)?.name)}&ratingEnabled=${!!(merchant?.reviewRequired || data?.reviewRequired)}`
                  const options = {
                    method: "POST",
                    url: `${BOG_URL}/payment/${sessionId}/3ds2-prepare/accept`,
                    params: {
                      version,
                      "device.channel": "BRW",
                      ...(version === "2.1.0" && {
                        "device.browserAcceptHeader":
                          "application/json, text/javascript, /; q=0.01",
                      }),
                      "device.browserIP": "127.0.0.1",
                      "device.browserLanguage":
                        version === "2.1.0" ? "en-US" : "EN",
                      ...(version === "2.1.0" && {
                        "device.browserJavaEnabled": false,
                      }),
                      "device.browserColorDepth":
                        version === "2.2.0" ? "32" : "24",
                      "device.browserScreenHeight": "800",
                      "device.browserScreenWidth": "480",
                      "device.browserTZ": "180",
                      "device.browserUserAgent":
                        version === "2.1.0"
                          ? "Mozilla/5.0 (Linux; Android 10; K) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/114.0.0.0 Mobile Safari/537.36"
                          : "Gecko",
                      ...(version === "2.2.0" && {
                        "device.browserJavascriptEnabled": false,
                      }),
                      challengeWindowSize: version === "2.2.0" ? "02" : "03",
                      // iframeReturnUrl,
                    },
                  };

                  const {
                    data: { url, post: { creq } = {} },
                  } = await axios.request(options);
                  if (!url || !creq) {
                    throw new Error("Something went wrong");
                  }
                  inputRef.current.value = creq;
                  formRef.current.action = url;
                  formRef.current.submit();
                } else if (state === "redirect") {
                  window.open(url, "_self");
                } else {
                  throw new Error("Unknow state");
                }
              } catch (err) {
                console.log(err);
              }
            })
            .catch((err) => {
              console.log(err);
            });
        } else if (paymentProviderEnum == "TBC") {
          const paymentDataRequest = Object.assign({}, baseRequest);
          paymentDataRequest.allowedPaymentMethods = [cardPaymentMethodTbc];
          paymentDataRequest.transactionInfo = {
            totalPriceStatus: "FINAL",
            totalPrice:
              currency == "EUR"
                ? convertedAmount.toString()
                : (((Math.ceil(amount * tip) / 100) * 100) / 100).toFixed(2),
            currencyCode: "GEL",
            countryCode: "GE",
          };
          paymentDataRequest.merchantInfo = {
            merchantName: "keepz",
            merchantId: "BCR2DN4TWWKZ77LS",
          };
          let googlePayResponse;
          try {
            googlePayResponse = await tipWithGooglePayTbc(
              transactionId,
              roundedTip,
              tip,
              roundedCommision,
              convertedAmount,
              currency
            );
          } catch (err) {
            console.log(err);

            return;
          }
          gpayRef.current
            .loadPaymentData(paymentDataRequest)
            .then(async (paymentData) => {
              try {
                const token =
                  paymentData.paymentMethodData.tokenizationData.token;
                const cardNetwork =
                  paymentData.paymentMethodData.info.cardNetwork;
                const { transactionId: merchantTransactionId } =
                  googlePayResponse;

                const { data } = await acceptTbcGooglePayPayment(
                  {
                    payment_method: "google_pay",
                    google_pay: {
                      card_network: cardNetwork,
                      token: token,
                    },
                  },
                  merchantTransactionId
                );

                if (data?.attributes?.status === "FINISHED") {
                  const redirectURL = `https://app.keepz.me/success?acquiringTransactionId=${merchantTransactionId}&receiverType=${receiverType}&amount=${(
                    (amount * tip) /
                    100
                  ).toFixed(
                    2
                  )}}&receiverName=${receiverName}&ratingEnabled=false&tipsEnabled=false&paymentProviderEnum=${paymentProviderEnum}&currency=${
                    currency == "EUR" ? "EUR" : "GEL"
                  }&currencyRate=${currencyRate}`;
                  window.open(redirectURL, "self");
                  return;
                } else if (data?.attributes?.status === "in_progress") {
                  console.log("in progress");
                } else if (data?.attributes?.status === "3ds2_prepare") {
                  console.log("3ds state");
                } else if (data?.attributes?.status === "redirect") {
                  window.open(data?.attributes?.status, "_self");
                } else {
                  throw new Error("Unknow state");
                }
              } catch (err) {
                console.log(err, "gooogle error");
              }
            })
            .catch((err) => {
              console.log(err);
            });
        } else if (paymentProviderEnum == "CREDO") {
          const paymentDataRequest = Object.assign({}, baseRequest);
          paymentDataRequest.allowedPaymentMethods = [cardPaymentMethodUfc];
          paymentDataRequest.transactionInfo = {
            totalPriceStatus: "FINAL",
            totalPrice:
              currency == "EUR"
                ? convertedAmount.toString()
                : (((Math.ceil(amount * tip) / 100) * 100) / 100).toFixed(2),
            currencyCode: "GEL",
            countryCode: "GE",
          };
          paymentDataRequest.merchantInfo = {
            merchantName: "keepz",
            merchantId: "BCR2DN4TXXE6LEZ5",
          };
          let googlePayResponse;
          try {
            googlePayResponse = await tipWithGooglePayCredo(
              transactionId,
              roundedTip,
              tip,
              roundedCommision,
              convertedAmount,
              currency
            );
          } catch (err) {
            console.log(err);

            return;
          }
          gpayRef.current
            .loadPaymentData(paymentDataRequest)
            .then(async (paymentData) => {
              try {
                const token =
                  paymentData.paymentMethodData.tokenizationData.token;
                const cardNetwork =
                  paymentData.paymentMethodData.info.cardNetwork;
                const { transactionId: merchantTransactionId } =
                  googlePayResponse;

                const { data } = await acceptUfcGooglePayPayment(
                  {
                    payment_method: "google_pay",
                    google_pay: {
                      card_network: cardNetwork,
                      token: token,
                    },
                  },
                  merchantTransactionId
                );

                if (data?.attributes?.status === "FINISHED") {
                  const redirectURL = `https://app.keepz.me/success?acquiringTransactionId=${merchantTransactionId}&receiverType=${receiverType}&amount=${(
                    (amount * tip) /
                    100
                  ).toFixed(
                    2
                  )}&receiverName=${receiverName}&ratingEnabled=false&tipsEnabled=false&paymentProviderEnum=${paymentProviderEnum}&currency=${
                    currency == "EUR" ? "EUR" : "GEL"
                  }&currencyRate=${currencyRate}`;
                  window.open(redirectURL, "_self");
                  return;
                } else if (data?.attributes?.status === "in_progress") {
                  console.log("in progress");
                } else if (data?.attributes?.status === "3ds2_prepare") {
                  console.log("3ds state");
                } else if (data?.attributes?.status === "redirect") {
                  window.open(data?.attributes?.status, "_self");
                } else {
                  throw new Error("Unknow state");
                }
              } catch (err) {
                console.log(err, "gooogle error");
              }
            })
            .catch((err) => {
              console.log(err);
            });
        }
      } else if (paymentMethod === "apple") {
        if (paymentProviderEnum == "BOG") {
          const transactionIdPromise = tipWithApplePay(
            transactionId,
            roundedTip,
            tip,
            roundedCommision,
            convertedAmount,
            currency
          );

          startApplePaySession(
            {
              amount:
                currency == "EUR"
                  ? convertToEur(Number(totalWithCommision) || roundedTip)
                  : Number(totalWithCommision) || roundedTip,
              transactionIdPromise: transactionIdPromise,
              // amount:
              //   currency == "EUR"
              //     ? convertToEur(Number(totalWithCommision) || roundedTip)
              //     : Number(totalWithCommision) || roundedTip,
              transactionIdPromise: transactionIdPromise,
            },
            (res) => {
              if (res?.status === 0) {
                const url = `https://app.keepz.me/success?acquiringTransactionId=${
                  res.transactionId
                }&amount=${((amount * tip) / 100).toFixed(
                  2
                )}&receiverName=${receiverName}&ratingEnabled=false&tipsEnabled=false&paymentProviderEnum=${paymentProviderEnum}&currency=${
                  currency == "EUR" ? "EUR" : "GEL"
                }&currencyRate=${currencyRate}`;
                window.open(url, "_self");
              } else {
                navigate(
                  `/payment-failed?acquiringTransactionId=${res.transactionId}&receiverId=${receiverId}&receiverType=${receiverType}`
                );
              }
            }
          );
        } else if (paymentProviderEnum == "TBC") {
          const transactionIdPromise = tipWithApplePayTbc(
            transactionId,
            roundedTip,
            tip,
            roundedCommision,
            convertedAmount,
            currency
          );

          startApplePaySessionTbc(
            {
              amount:
                currency == "EUR"
                  ? convertToEur(Number(totalWithCommision) || roundedTip)
                  : Number(totalWithCommision) || roundedTip,
              transactionIdPromise: transactionIdPromise,
              // amount:
              //   currency == "EUR"
              //     ? convertToEur(Number(totalWithCommision) || roundedTip)
              //     : Number(totalWithCommision) || roundedTip,
              transactionIdPromise: transactionIdPromise,
            },
            (res) => {
              if (res?.status === 0) {
                const url = `https://app.keepz.me/success?acquiringTransactionId=${
                  res.transactionId
                }&amount=${((amount * tip) / 100).toFixed(
                  2
                )}&receiverName=${receiverName}&ratingEnabled=false&tipsEnabled=false&paymentProviderEnum=${paymentProviderEnum}&currency=${
                  currency == "EUR" ? "EUR" : "GEL"
                }&currencyRate=${currencyRate}`;
                window.open(url, "_self");
              } else {
                navigate(
                  `/payment-failed?acquiringTransactionId=${res.transactionId}&receiverId=${receiverId}&receiverType=${receiverType}`
                );
              }
            }
          );
        } else if (paymentProviderEnum == "CREDO") {
          const transactionIdPromise = tipWithApplePayCredo(
            transactionId,
            roundedTip,
            tip,
            roundedCommision,
            convertedAmount,
            currency
          );

          startApplePaySessionUfc(
            {
              // amount: totalWithCommision
              //   ? Number(totalWithCommision)
              //   : roundedTip,
              amount:
                currency == "EUR"
                  ? convertToEur(Number(totalWithCommision) || roundedTip)
                  : Number(totalWithCommision) || roundedTip,
              transactionIdPromise: transactionIdPromise,
            },
            (res) => {
              if (res?.status === 0) {
                const url = `https://app.keepz.me/success?acquiringTransactionId=${
                  res.transactionId
                }&amount=${((amount * tip) / 100).toFixed(
                  2
                )}&receiverName=${receiverName}&ratingEnabled=false&tipsEnabled=false&paymentProviderEnum=${paymentProviderEnum}&currency=${
                  currency == "EUR" ? "EUR" : "GEL"
                }&currencyRate=${currencyRate}`;
                window.open(url, "_self");
              } else {
                navigate(
                  `/payment-failed?acquiringTransactionId=${res.transactionId}&receiverId=${receiverId}&receiverType=${receiverType}`
                );
              }
            }
          );
        }
      } else if (paymentMethod == "OB") {
        // const transactionId = localStorage.getItem("ob_id");
        // const openBankingProviderId = localStorage.getItem("obProvideId");

        const { scaOauthUrl, id, codeVerifier } = await tipOBRequest(
          transactionId,
          roundedTip,
          tip,
          roundedCommision
          // openBankingProviderId
        );
        localStorage.setItem("ob_id", id);

        // localStorage.setItem("state", state);
        localStorage.setItem("codeVerifier", codeVerifier);
        window.open(scaOauthUrl, "_self");
      }
    } catch (error) {
      console.log(error);
      setIsSuccess(false);
      setShowModal(true);
    }
  };

  //Initialize google pay
  useEffect(() => {
    if (paymentMethod !== "google") {
      return;
    }
    const script = document.createElement("script");
    script.src = "https://pay.google.com/gp/p/js/pay.js";
    script.onload = () => {
      // eslint-disable-next-line no-undef
      gpayRef.current = new google.payments.api.PaymentsClient({
        environment: "PRODUCTION", // Change to 'PRODUCTION' for production use
      });
      const isReadyToPayRequest = Object.assign({}, baseRequest);
      isReadyToPayRequest.allowedPaymentMethods = [baseCardPaymentMethod];
      gpayRef.current
        .isReadyToPay(isReadyToPayRequest)
        .then(({ result }) => {
          if (result) {
            console.log(result);
          } else {
            throw new Error("Gpay not available");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    };

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  let MERCHANT_ID;
  if (paymentProviderEnum == "BOG") {
    MERCHANT_ID = MERCHANT_IDENTIFIER;
  } else if (paymentProviderEnum == "TBC") {
    MERCHANT_ID = MERCHANT_IDENTIFIER_TBC;
  } else {
    MERCHANT_ID = MERCHANT_IDENTIFIER_CREDO;
  }
  //Initialize apple pay
  useEffect(() => {
    if (window.ApplePaySession) {
      const promise =
        window.ApplePaySession.canMakePaymentsWithActiveCard(MERCHANT_ID);
      promise
        .then((canMakePayments) => {
          if (canMakePayments) {
            console.log("can make apple payment");
          } else {
            throw new Error("can't make payments");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      console.log("Apple pay not available");
    }
  }, []);

  return (
    <>
      {/* {inputModal && (
        <CustomInputModal
          tip={tip}
          setTip={setTip}
          setShowModal={setShowModal}
          transactionId={transactionId}
          setIsSuccess={setIsSuccess}
          isSuccess={isSuccess}
          onClose={() => setInputModal(false)}
        />
      )} */}

      <form ref={formRef} method="post" action="">
        <input type="hidden" name="creq" ref={inputRef} value="" />
        <noscript>
          <center>
            <br />
            Please click the submit button below.
            <br />
            <input type="submit" value="Submit" />
          </center>
        </noscript>
      </form>
      {showModal ? (
        <TipModal
          isSuccess={isSuccess}
          onClose={() => {
            setShowModal(false);
          }}
        />
      ) : null}
      <div className="flex items-center justify-center gap-[10px]">
        <a
          href="https://apps.apple.com/us/app/tip-app-mobile/id1579469134"
          target="_blank"
          rel="noreferrer"
        >
          <img src={apple} alt="#" width="158px" height="52px" />
        </a>
        <a
          href="https://play.google.com/store/apps/details?id=com.tipapp"
          target="_blank"
          rel="noreferrer"
        >
          <img src={googleIcon} alt="#" width="158px" height="52px" />
        </a>
      </div>
      {tipsEnabled ? (
        <>
          <div className="flex flex-col justify-center items-center mt-[15px] gap-[10px]">
            <p className="text-[#6C63FF] text-[16px] font-sans">
              {t("tip")}:{" "}
              {tip && `${(roundedTip + roundedCommision).toFixed(2)}`}
            </p>

            <div className="flex items-center justify-center gap-[20px]  flex-wrap">
              <button
                onClick={() => setTip(5)}
                className={`py-[10px] px-[20px] h-[40px] ${
                  tip === 5 ? "bg-[#6C63FF] text-white" : "bg-[#FAFAFA]"
                } rounded-[16px] font-sans`}
              >
                5%
              </button>
              <button
                onClick={() => setTip(10)}
                className={`py-[10px] px-[20px] h-[40px] ${
                  tip === 10 ? "bg-[#6C63FF] text-white" : "bg-[#FAFAFA]"
                } rounded-[16px] font-sans`}
              >
                10%
              </button>
              <button
                onClick={() => setTip(15)}
                className={`py-[10px] px-[20px] h-[40px] ${
                  tip === 15 ? "bg-[#6C63FF] text-white" : "bg-[#FAFAFA]"
                } rounded-[16px] font-sans`}
              >
                15%
              </button>
              <button
                onClick={() => setTip(20)}
                className={`py-[10px] px-[20px] h-[40px] ${
                  tip === 20 ? "bg-[#6C63FF] text-white" : "bg-[#FAFAFA]"
                } rounded-[16px] font-sans`}
              >
                20%
              </button>
              {/* <button
                disabled={isSuccess}
                // onClick={() =>
                //   navigate("/custom-tip", {
                //     state: { transactionId: transactionId },
                //   })
                // }
                onClick={() => setInputModal(true)}
                className={`py-[10px] px-[20px] h-[40px] bg-[#170738]/5 rounded-[16px] font-sans disabled:cursor-not-allowed`}
              >
                other
              </button> */}
            </div>
          </div>
          <button
            className="w-[330px] p-[10px] rounded-[16px] bg-[#6C63FF] text-[#fff] m-auto mt-[20px] disabled:cursor-not-allowed disabled:opacity-50"
            disabled={isSuccess || !tip}
            onClick={handlTips}
          >
            {t("buttons.send")}
          </button>
        </>
      ) : null}

      <div className="flex items-center justify-center mt-[38px] w-full pb-10 self-end p-4">
        <button
          className="max-w-2xl  w-full inline-flex justify-center gap-2 p-4 text-[20px] text-[#6C63FF] font-[600] mt-4 rounded-2xl disabled:bg-gray-400 text-center items-center"
          style={{ height: "50px", borderRadius: "8px" }}
          onClick={() => {
            if (localStorage.getItem("integratorOrderId")) {
              skip();
            } else {
              const currentURL = window.location.href;
              const url = new URL(currentURL);
              const newURL = `${url.origin}`;
              window.location.href = newURL;
            }
          }}
          // to="https://app.keepz.me"
        >
          {t("description.done")}
        </button>
      </div>
    </>
  );
}
